@use '@/styles/utils' as *;

.headerContainer {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-block-end: 1rem;
    width: 100%;

    h2 {
        font-size: 3.2rem;
        line-height: 5.037rem;
    }

    .timePeriodBtns {
        align-items: center;
        display: flex;
        gap: 0.5rem;
        justify-content: center;
    }

    @media (width <= $container-width-mid) {
        flex-direction: column;
        margin-block-end: 1.8rem;
    }

    @media (width <= $container-width-min) {
        gap: 0.1rem;

        h2 {
            font-size: 2.4rem;
            font-weight: 400;
            line-height: 3.778rem;
            text-align: center;
        }
    }
}

.dataTable {
    width: 100%;
}

.headersRow,
.dataRow,
.leaderboardError,
.personalStatsSignIn,
.personalRecordsError,
.personalStats {
    @include button;

    background-color: rgb(255 255 255 / 10%);
    border: 1px solid rgb(255 255 255 / 30%);
    border-radius: 1.6rem;
    color: #fff;
    cursor: auto;
    display: grid;
    font-size: 1.6rem;
    grid-template-columns: 1fr 3fr 1fr;
    letter-spacing: 0.125rem;
    line-height: 2.08rem;
    margin-block: 0.6rem;
    padding: 0 2.4rem;
    width: 100%;

    @media (width <= $container-width-min) {
        font-size: 1.4rem;
        letter-spacing: 0.125rem;
        line-height: 1.82rem;
    }

    @media (width <= $container-width-mobile) {
        font-size: 1.2rem;
        padding: 0 2rem;
    }

    @media (width <= $container-width-mobile-small) {
        font-size: 1rem;
        grid-template-columns: 1fr 2fr 1fr;
    }

    td:nth-child(1) {
        text-align: left;
    }

    td:nth-child(3) {
        text-align: right;
    }

    td {
        border: none;
        padding-block: 1.6rem;
        text-align: center;
    }

    &:hover {
        opacity: 1;
    }
}

.headersRow {
    background-color: rgb(255 255 255 / 50%);
    color: #000;
    gap: 0.8rem;
    margin-block-end: 1.8rem;

    @media (width <= $container-width-mobile) {
        gap: 0.5rem;
    }

    td {
        @include break-word;

        align-self: center;
        hyphens: none;
    }
}

.leaderboardError {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 10.15rem 25.2rem;
    text-align: center;

    td:nth-child(1) {
        text-align: center;
        text-transform: none;
        width: 100%;
    }

    @media (width <= $container-width-min) {
        font-size: 1.8rem;
        letter-spacing: 0.125rem;
        line-height: 2.34rem;
        padding: 9.85rem 2.4rem;
    }
}

.personalStatsSignIn,
.personalStats {
    background-color: rgb(1 163 197 / 70%);
    border: 1px solid var(--red-dark-blue, rgb(1 163 197 / 100%));
    color: $color-base;
    cursor: pointer;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-block: 2.4rem 0.6rem;
    opacity: 1;
    padding: 1.2rem 2.4rem;
    text-align: center;
    transition: background-color 200ms ease-in-out;

    @media (width <= $container-width-min) {
        padding: 0.7rem 2.4rem;
    }

    &:hover {
        background-color: rgb(1 163 197 / 100%);
    }
}

.personalStats {
    cursor: auto;
    padding-block: 0;
    pointer-events: none;

    .dataRow {
        background: none;
        border: none;
        grid-template-columns: 1fr 16fr 1fr;
        padding: 0;

        @media (width <= $container-width-min) {
            grid-template-columns: 1fr 1fr 1fr;

            td:nth-child(2) {
                text-align: center;
            }
        }

        td:nth-child(2) {
            text-align: left;
        }
    }
}

.personalBest {
    background-color: var(--red-dark-blue, #01a3c5);
    border-radius: 0.5rem;
    color: #fff;
    font-family: $fonts-saira;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.204rem;
    margin-block: 1.4rem;
    max-width: max-content;
    padding-inline: 1rem;
    text-transform: uppercase;
}

.personalRecordsError {
    background-color: rgb(166 83 83 / 30%);
    border: none;
    color: rgb(255 0 0 / 100%);
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-block: 2.4rem 0.6rem;
    opacity: 1;
    padding: 1.6rem 2.4rem;
    text-align: center;
}

.leaderboardNav {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: $fonts-saira;
    font-size: 1.8rem;
    font-weight: 400;
    gap: 1.6rem;
    justify-content: center;
    line-height: 1.98rem;
    margin-block-start: 1.8rem;

    @media (width <= $container-width-min) {
        font-size: 1.4rem;
        line-height: 1.54rem;
    }
}

.spinnerContainer {
    min-height: 44.8rem;
    min-width: 100%;
    position: relative;

    @media (width <= $container-width-min) {
        min-width: 100%;
    }

    .spinner {
        height: 10rem;
        left: calc(50% - 5rem);
        position: absolute;
        top: calc(50% - 5rem);
        width: 10rem;
    }
}
