@use '@/styles/utils' as *;

.arrow {
    opacity: 0.4;
    transition: opacity 200ms ease-in-out;

    &.previous {
        transform: scaleX(-1);
    }

    &:hover {
        opacity: 1;
    }
}
