@use '@/styles/utils' as *;

.submitButton {
    align-items: baseline;
    border-image: url('/assets/images/tape-effect--blue.svg');
    border-image-outset: 8px 8px 8px 8px;
    border-image-repeat: stretch stretch;
    border-image-slice: 0 0 0 0 fill;
    border-image-width: 10px 10px 10px 10px;
    color: $color-base;
    column-gap: 0.5rem;
    display: inline-flex;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    letter-spacing: 1.25px;
    line-height: normal;
    padding: 2rem 5rem;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap; // Need this to prevent character langs from wrapping in SVG
    width: 100%;
    z-index: 2;

    &[aria-disabled='true'] {
        opacity: 0.7;
    }

    &:focus-visible {
        outline: dashed 1px currentcolor;
    }
}

.tape {
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
}
