@use '@/styles/utils' as *;

.container {
    margin: 2.4rem auto;
    max-width: 82.9rem;
    position: relative;
    text-align: center;
    width: 100%;

    .title {
        border-image: url('/assets/images/tape-effect--black.svg');
        border-image-outset: 8px 8px 8px 8px;
        border-image-repeat: stretch stretch;
        border-image-slice: 0 0 0 0 fill;
        border-image-width: 10px 10px 10px 10px;
        color: #fff;
        font-size: 4.7rem;
        font-weight: 400;
        line-height: 7.398rem;
        margin-block: 0.946rem;
        padding: 2rem 5rem;
        position: relative;
        text-transform: uppercase;
        transform: rotate(-2.02deg);
        z-index: 2;

        @media (width <= $container-width-min) {
            font-size: 2.4rem;
            line-height: 3.778rem;
            margin-block: 1.26rem;
            padding: 1.805rem 3.191rem;
        }
    }

    .disclaimer {
        color: #858585;
        font-family: $fonts-saira;
        font-size: 2.4rem;
        line-height: 3.778rem;
        text-transform: none;

        @media (width <= $container-width-min) {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
    }
}
