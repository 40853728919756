@use '@/styles/utils' as *;

.title {
    color: rgb(20 20 20 / 50%);
    font-size: 2.4rem;
    letter-spacing: 0.025rem;
    line-height: 3.778rem;
    text-align: center;

    @media (width <= $container-width-min) {
        font-size: 1.8rem;
        letter-spacing: 0.125rem;
        line-height: 2.34rem;
    }
}

.data {
    font-size: 3.8rem;
    letter-spacing: 0.025rem;
    line-height: 5.981rem;
    text-align: center;

    @media (width <= $container-width-min) {
        font-size: 2.4rem;
        letter-spacing: 0.025rem;
        line-height: 3.778rem;
    }
}

.container {
    .tilted {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-block-start: 0;
        min-height: 26.2rem;
        min-width: 33rem;
        padding: 0;

        @media (width <= $container-width-min) {
            min-height: 20.03rem;
            min-width: 32rem;
        }

        h3 {
            margin-bottom: 0;
        }
    }

    .tilted-0 {
        transform: rotate(1.6deg);
    }

    /* No tilt on the 2nd stat */

    .tilted-2 {
        transform: rotate(-0.36deg);
    }

    .tilted-3 {
        transform: rotate(-3.22deg);
    }

    .tilted-4 {
        transform: rotate(-0.16deg);
    }

    .tilted-5 {
        transform: rotate(2.59deg);
    }

    .hidden {
        display: none;
    }
}
