@use '@/styles/utils' as *;

.container {
    align-items: center;
    background-image: url('/assets/images/leaderboards-background.png');
    background-position: center top;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 10rem 10rem 20rem;
    width: 100%;

    @media (width <= $container-width-min) {
        padding: 4.19rem 3.173rem 12.39rem;
    }

    @media (width <= 500px) {
        background-image: url('/assets/images/leaderboards-background-mobile.png');
        background-size: cover;
    }

    .statsContainer {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        gap: 0.8rem;
        justify-content: center;
    }
}
