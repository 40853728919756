@use '@/styles/utils' as *;

.titleContainer {
    position: relative;

    .label {
        color: $color-gray-1000;
        font-size: 1.6rem;
        font-weight: 400;
        left: 1.6rem;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        @media (prefers-reduced-motion: no-preference) {
            transition: top 200ms;
        }
    }

    &:focus-within .label,
    .label.valid {
        font-size: 1.2rem;
        line-height: 1.3;
        position: absolute;
        top: 1.6rem;
    }
}

.labelContainer {
    position: relative;
}

.label {
    font-size: 1.2rem;
    font-weight: 400;
    left: 2.1rem;
    line-height: 1.3;
    position: absolute;
    top: 1rem;
}
