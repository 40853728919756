@use '@/styles/utils' as *;

.userActionSection {
    --margin-adjustment: 15rem;
    --buffer: 120px;

    margin-block-start: (calc(var(--buffer) * -1));
    position: relative;
    scroll-margin-block-start: 5rem;
    z-index: 3;
}

.backgroundWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 3;
}

.layout {
    --margin-adjustment: 10rem;

    align-items: center;
    column-gap: 3.2rem;
    display: grid;
    grid-template:
        'actions mainImage' auto
        / 1fr 1fr;
    margin-block: var(--margin-adjustment);
    padding: 3.2rem;
    width: 100%;

    @media (width <= 1200px) {
        grid-template:
            'mainImage' auto
            'actions' auto
            / 1fr;
        margin-block-end: var(--margin-adjustment);
        padding-inline: 4rem;
        row-gap: 3.2rem;
    }
}

.paperContent {
    margin-block-start: 2rem;
}

.actions {
    color: white;
    display: flex;
    flex-direction: column;
    grid-area: actions;
    height: 100%;
    justify-content: center;
    justify-self: start;
    width: 100%;

    @media (width > 900px) {
        justify-self: center;
        max-width: 616px;
    }

    h4 {
        margin-block-end: 2rem;
    }
}

.backgroundImage {
    clip-path: polygon(0 calc(var(--buffer) - 10px), 100% 10px, 100% 100%, 0 100%);
    object-fit: cover;
}

.tearEffect {
    fill: #000;
    height: var(--buffer);
    left: 0;
    position: absolute;
    top: 0;
    transform: scaleX(-1);
    translate: 0 0%;
    width: 100%;
}

.mainImage {
    grid-area: mainImage;
    position: relative;
    width: 100%;

    &::before {
        background-color: $color-gray-200;
        content: '';
        inset: 0;
        mask-image: url('/assets/images/frame-experiment.png');
        mask-repeat: no-repeat;
        mask-size: 100% 100%;
        position: absolute;
        z-index: 4;
    }

    img {
        height: auto;
        object-fit: cover;
        width: 100%;
    }
}

.newsletterImage {
    mask-image: url('/assets/images/frame-experiment-4.png');
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
}
