@use '@/styles/utils' as *;

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8rem auto;
    max-width: 100%;
    position: relative;
    text-align: center;
    z-index: 1;

    .desktopBG {
        display: block;
        height: auto;
        margin-block-end: 4rem;
        max-width: 100%;
        object-fit: contain;
        position: relative;

        @media (width <= $container-width-min) {
            display: none;
        }
    }

    .mobileBG {
        display: none;
        height: auto;
        margin-block: 3rem;
        max-width: 100%;
        object-fit: contain;
        position: relative;

        @media (width <= $container-width-min) {
            display: block;
        }
    }

    @media (width <= $container-width-min) {
        margin-block: 0 3rem;
    }

    .contentContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .title {
            font-size: 3.2rem;
            font-weight: 400;
            line-height: 3.52rem;
            text-transform: uppercase;

            @media (width <= $container-width-min) {
                font-size: 2.4rem;
                line-height: 2.64rem;
            }
        }

        .instructions {
            > ol {
                margin-block: 2.4rem 3.2rem;
                padding-inline-start: 0;
                text-align: center;

                > li {
                    @include break-word;

                    color: rgb(255 255 255 / 60%);
                    font-family: $fonts-poppins;
                    font-size: 1.8rem;
                    font-style: normal;
                    font-weight: 600;
                    letter-spacing: 0.05rem;
                    line-height: 2.34rem;
                    list-style-position: inside;

                    @media (width <= $container-width-min) {
                        font-size: 1.4rem;
                        font-weight: 500;
                        letter-spacing: 0.05rem;
                        line-height: 1.82rem;
                    }
                }

                @media (width <= $container-width-min) {
                    margin-block: 2rem;
                }
            }
        }

        .buttonContainer {
            display: flex;
            flex-direction: row;
            gap: 1rem;

            @media (width <= $container-width-min) {
                flex-direction: column;
                width: 100%;
            }
        }

        .login,
        .seeMore {
            @include button;

            color: $color-base;
            color: #000;
            font-size: 1.6rem;
            font-weight: 400;
            letter-spacing: 1.25px;
            line-height: 2.08rem;
            position: relative;
            text-transform: uppercase;
            transition: opacity 200ms ease-in-out;
            width: minmax(max-content, 30rem);
            z-index: 2;

            &:hover {
                opacity: 0.8;
            }

            svg {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: -1;
            }

            span {
                @include break-word;

                display: inline-block;
                max-width: 100%;
                padding: 1.4rem 1.5em;
            }

            @media (width <= $container-width-min) {
                font-size: 1.5rem;
                letter-spacing: 0.0125rem;
                line-height: 1.95rem;
                width: 100%;
            }
        }

        .login {
            svg {
                fill: #ff4e4e;
            }
        }

        .seeMore {
            svg {
                fill: #01a3c5;
            }
        }
    }
}
