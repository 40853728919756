@use '@/styles/utils' as *;

.leaderboardContainer {
    margin-block-end: 10rem;
    position: relative;
    width: 100%;

    @media (width <= $container-width-min) {
        margin-block: 3rem 4.48rem;
    }
}
