@use '@/styles/utils' as *;

// ======== Form Styling ========

.form {
    @include paragraph-2;

    align-items: center;
    display: grid;
    gap: 1.6rem;
    grid-template:
        'dob dob' auto
        'legal legal' auto
        'marketing marketing' auto
        'email submit' auto
        'success success' auto
        'error error' auto
        / 4fr 1fr;
    justify-content: space-between;
    margin-block-start: 2rem;
    position: relative;

    @media (width <= 900px) {
        grid-template:
            'dob' auto
            'legal' auto
            'marketing' auto
            'email' auto
            'submit' auto
            'success' auto
            'error' auto
            / 1fr;
    }

    .error {
        background-color: $color-light-pink;
        border-color: $color-error;
    }

    input {
        flex: 0 0 auto;
    }

    a {
        font-size: inherit;
    }
}

.legal,
.marketing {
    align-items: center;
    background-color: $color-gray-300;
    border: 1px solid transparent;
    column-gap: 0.8rem;
    display: inline-flex;
    padding: 0.8rem;
    width: 100%;

    input {
        grid-area: checkbox;
        height: 25px;
        width: 25px;
    }

    .marketingCopy {
        grid-area: label;
    }

    span {
        color: $color-gray-1000;

        &.inlineError {
            color: $color-error;
            grid-area: error;
            margin-block-start: 0;
            margin-inline-start: auto;
        }
    }

    &.checkboxError {
        background-color: $color-light-pink;
        border-color: $color-error;
    }

    @media (width <= 500px) {
        display: grid;
        gap: 1.5rem;
        grid-template:
            'checkbox label' auto
            'error error' auto / 25px 1fr;

        span {
            &.inlineError {
                margin-inline-start: 0;
            }
        }
    }
}

.dobWrapper {
    grid-area: dob;
}

.legalWrapper {
    grid-area: legal;
}

.marketingWrapper {
    grid-area: marketing;
}

.email {
    grid-area: email;
    position: relative;
}

.submitButton {
    grid-area: submit;
    margin-block-end: auto;
    position: relative;
}

.successMessage {
    background: white;
    color: green;
    column-gap: 0.8rem;
    display: inline-flex;
    grid-area: success;
    padding: 0.8rem;
    text-wrap: wrap;
}

.successCircle {
    width: 1.6rem;
}

.errors {
    grid-area: error;
}

.boldLink {
    a {
        font-weight: bold;
    }
}
