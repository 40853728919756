@use '@/styles/utils' as *;

.switchTime {
    color: $color-base;
    color: #000;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.25px;
    line-height: 2.08rem;
    position: relative;
    text-transform: uppercase;
    transition: color 200ms ease-in-out;
    z-index: 2;

    &:hover {
        opacity: unset;
    }

    &.allTime {
        &.isActive {
            pointer-events: none;

            svg {
                fill: #ff4e4e;
            }
        }

        &:hover {
            svg {
                fill: #ff4e4e;
                opacity: 0.8;
            }
        }
    }

    &.thisMonth {
        &.isActive {
            pointer-events: none;

            svg {
                fill: #01a3c5;
            }
        }

        &:hover {
            svg {
                fill: #01a3c5;
                opacity: 0.8;
            }
        }
    }

    span {
        display: inline-block;
        padding: 1rem 2rem;

        @media (width <= $container-width-min) {
            padding: 1rem 1.2rem;
        }
    }

    svg {
        fill: #686868;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: fill 200ms ease-in-out;
        width: 100%;
        z-index: -1;
    }

    @media (width <= $container-width-min) {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.82rem;
    }
}
