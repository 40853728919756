.spinner {
    @keyframes spinner {
        to {
            rotate: 360deg;
        }
    }

    animation: spinner 600ms linear infinite;
    border: 0.2em solid;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    display: inline-block;
    height: 1em;
    translate: 0 0.15em;
    width: 1em;
}
